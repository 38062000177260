<template>
  <div>
    <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-bullet-list-67"></i> Early warning - Perjam
      </h6>
    </h1>

    <base-header class="pb-6">
        <b-row>
          <b-col xl="12" md="12">
            <b-card style="position: relative;overflow: hidden;">

              <h3>Cari Berdasarkan</h3>

              <div class="d-flex justify-content-start" style="position: relative;z-index: 999;">
                <!-- Tanggal -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;">
                  <label><small>Tanggal</small></label>
                  <base-input name="Tanggal">
                    <flat-picker
                      placeholder="Filter Tanggal"
                      :config="provideSelect.flatPickerConfig"
                      class="form-control datepicker"
                      v-model="userInput.tanggal"
                      style="background:white;"
                    />
                  </base-input>
                </div>

                <!-- Jenis Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Jenis Industri</small></label>
                  <base-input>
                    <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType" @change="resetIndustry()">
                      <el-option value="">Semua Jenis Industri</el-option>
                      <el-option
                        v-for="option, idx in provideSelect.companyType"
                        :key="idx"
                        :label="option.name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>

                <!-- Provinsi -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Provinsi</small></label>
                  <base-input name="Provinsi" >
                      <el-select
                        v-model="form.umum.compProvince"
                        @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                        <el-option
                        label="Semua Provinsi"
                        :value=null
                        >
                        </el-option>
                        <el-option
                          v-for="option in provideSelect.provinsi"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota" >
                      <el-select
                        filterable
                        v-model="form.umum.compCity"
                        >
                        <el-option
                          v-for="option in provideSelect.kabupatenKota"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'"  >
                  <label><small>Nama Industri</small></label>
                  <base-input name="Nama Industri" >
                      <el-select
                        filterable
                        v-model="userInput.companyName"
                        @change="getMyCompliencePoint"
                        >
                        <el-option value="">Semua</el-option>
                        <el-option
                          v-for="option in provideSelect.companyName"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <!-- Titik Penaatan -->
                <div style="display: flex;flex-direction: column;width: 180px;">
                  <label><small>Titik Penaatan</small></label>
                  <base-input>
                      <el-select filterable placeholder="Titik Penaatan" v-model="userInput.titikPenaatan">
                        <el-option
                          v-for="option in listDataCompliencePoints"
                          :key="option.id"
                          :label="option.spot_name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>
              </div>

              <base-button size="md" type="primary" @click="getBaseOnStatus"> Tampilkan </base-button>
              <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -100px;z-index: 50;opacity: 0.1;">
            </b-card>
          </b-col>
        </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <b-row>
                <b-col lg="6" cols="7">
                  <!-- <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Total sales</h5> -->
                </b-col>
                <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
                <button
                  class="btn text-white btn-sm"
                  style="background-color: #b51308;"
                  @click="downloadPDFNow"
                >
                  <i class="fa fa-solid fa-file-pdf mr-2" style="font-size: 20px;"></i>
                    Unduh PDF
                </button>
                <button
                  class="btn text-white btn-sm"
                  style="background-color: #0a7641;"
                  @click="downloadExcelNow"
                >
                  <i class="fa fa-solid fa-file-excel mr-2" style="font-size: 20px;"></i>
                    Unduh Excel
                </button>
              </b-col>
              </b-row>
            </template>
            <!-- <EarlyWarningJamTable :tableData="listData"/> -->

            <section id="mytable">
              <el-table
                :data="listData"
                row-key="id"
                header-row-class-name="thead-light"
              >
                <el-table-column label="No" type="index" width="100" />

                <el-table-column min-width="150px"  label="Nama Industri" v-if="log_as == 'admin'">
                  <div slot-scope="action">
                    {{ action.row.comp_name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="150px"  label="Nama Titik Penaatan">
                  <div slot-scope="action">
                    {{ action.row.spot_name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="140px"  label="Jenis Industri" v-if="log_as == 'admin'">
                  <div slot-scope="action">
                    {{ action.row.comp_type_name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="110px"  label="Provinsi" v-if="log_as == 'admin'">
                  <div slot-scope="action">
                    {{ action.row.province_name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="130px"  label="Kabupaten / Kota" v-if="log_as == 'admin'">
                  <div slot-scope="action">
                    {{ action.row.kabkot_name}}
                  </div>
                </el-table-column>

                <el-table-column min-width="130px" label="Nilai">
                  <div slot-scope="action">
                    {{ action.row.detail.parameter }} : {{ action.row.detail.value }}
                  </div>
                </el-table-column>

                <el-table-column min-width="180px" label="Keterangan">
                  <div slot-scope="action">
                    <span class="text-danger">{{ action.row.detail.message }}</span>
                  </div>
                </el-table-column>

                <el-table-column min-width="120px" label="Surat Teguran">
                  <div slot-scope="action">
                    <a href="https://sparingengine.ppkl.menlhk.go.id/storage/S.140%20(Peringatan%20data%20melebihi%20baku%20mutu%20air%20limbah).pdf" target="blank"><button class="btn btn-primary btn-sm">Unduh</button></a>
                  </div>
                </el-table-column>

                <el-table-column min-width="150px" label="dibuat">
                  <div slot-scope="action">
                    <!-- {{ formatDate(action.row.timestamp) }} -->
                  </div>
                </el-table-column>

              </el-table>

              <div class="py-3">
                <el-select
                  class="select-primary pagination-select mr-3"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                  @change="changeSizePage()"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <small class="card-category">
                  Showing {{ 1 }} to {{ listData.length }} of
                  {{ pagination.total }} entries
                </small>
              </div>

              <div style="display: flex; justify-content: flex-end">
                <base-pagination
                  class="pagination-no-border"
                  :current="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="pagination.total"
                  @change="paginationChanged($event)"
                >
                </base-pagination>
              </div>
            </section>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Loading Screen -->
    <Modal
      :show="isLoading"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="lg"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative;">
                <img src="img/brand/img-logo-color.png" alt="">
                <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -200px;opacity: 0.2;">
                <h1>Menyiapkan Data...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Download Modal PDF -->
    <Modal
      :show="showDownloadModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModal = false"
      size="xl"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4" style="height: 500px; overflow: scroll">
            <div id="printBos">
              <div id="headerpdf">
                <div style="display: flex; justify-content: space-between">
                  <div>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <div style="width: 50px; height: 50px">
                        <img
                          src="/img/brand/img-logo-color.png"
                          alt=""
                          style="width: 100%"
                        />
                      </div>
                      <h3 class="ml-3">Kementerian Lingkungan Hidup</h3>
                    </div>
                  </div>
                  <div>
                    <h2>{{ pdfName }}</h2>
                    <small><b>Early Warning - Perjam</b></small
                    ><br />
                  </div>
                </div>
                <table class="table mt-3">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-white">No</th>
                      <th class="text-white">Nama Industri</th>
                      <th class="text-white">Waktu</th>
                      <th class="text-white">Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in listDataDownload.items">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ item.company_name }}</td>
                      <td>{{ formatDate(item.timestamp) }}</td>
                      <td>{{ item.message }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- <div class="mt-5">
              <button class="btn btn-success" @click="exportExcel">Unduh Excel</button>
            </div> -->

            <!-- <ReportExcel/> -->
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <button class="btn btn-primary" @click="print">Unduh PDF</button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Download Modal Excel -->
    <Modal
      :show="showDownloadModalCSV"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModalCSV = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4" style="height: 500px;overflow: scroll;">
            <div>
              <div>

                <div style="padding:10px;overflow-x: scroll;" >
                  <table class="table mt-3" ref="exportable_table">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-white">No</th>
                      <th class="text-white">Nama Industri</th>
                      <th class="text-white">Waktu</th>
                      <th class="text-white">Nama Parameter</th>
                      <th class="text-white">Nilai</th>
                      <th class="text-white">Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in listDataDownload.items">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ item.company_name  }}</td>
                      <td>{{ formatDate(item.timestamp) }}</td>
                      <td>None</td>
                      <td>None</td>
                      <td>{{ item.message }}</td>
                    </tr>
                  </tbody>
                </table>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="ExportExcel('xlsx')"
            >
              Unduh Excel
            </base-button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModalCSV = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import API from "../../../api/base_url";
import BaseHeader from "@/components/BaseHeader";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Select, Option, Table, TableColumn } from "element-ui";
import EarlyWarningJamTable from "../../Tables/EarlyWarningJamTable.vue";
import moment from 'moment'
// new
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";




export default {
  components: {
    Modal,
    BaseHeader,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    EarlyWarningJamTable,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  mounted() {
    this.log_as = localStorage.getItem("as");
    this.getData()
    this.getCompanyType()
    this.getCompanyName()
    this.getCompliencePoint()
    this.getProvince()
  },
  data() {
    return { 
      pdfName:"",
      listDataDownload: [],
      listDataCompliencePoints: [],
      filterStatusPendaftaran: "",
      userInput: {
      tanggal: "",
      registrationType:"SP",
      titikPenaatan:"",
      companyType:"",
      companyName:"",
      provinsi:[],
      kabupatenKota:[],
      regency:[]
      },
      provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,
          plugins: [
            ShortcutButtonsPlugin({
              button: [
                {
                  label: "Data Hari ini",
                  attributes:{
                    class:"btn w-75 btn-dark mb-2 btn-sm"
                  }
                },
                {
                  label: "Data 1 Minggu",
                  attributes:{
                    class:"btn w-75 btn-dark mb-2 btn-sm"
                  }
                },
                {
                  label: "Data 1 Bulan",
                  attributes:{
                    class:"btn w-75 btn-dark mb-5 btn-sm"
                  }
                },
                {
                  label: "Reset Pencarian Tanggal",
                  attributes:{
                    class:"btn w-100 btn-danger btn-sm"
                  }
                },
              ],
              onClick:(index,fp)=>{
                fp.clear();

                if(index == 0){
                const startDate = moment().startOf('day');
                  const endDate = moment(startDate).endOf('day');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                  
                }else if(index == 1){
                  const startDate = moment().startOf('day');
                  const endDate = moment(startDate).add(1, 'week').endOf('day');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);

                 
                }else if(index == 2){
                  const startDate = moment().startOf('month');
                  const endDate = moment().endOf('month');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                }else{
                  fp.clear();
                }
              }
            }),
          ], // Gunakan plugin di sini

          defaultHour: 0,
          mode: "range",
        },
        registrationType:[],
        compiencePoint:[],
        companyType:[],
        companyName:[],
        provinsi:[],
        kabupatenKota:[],
        regency:[]
      },
      form: {
        umum: {
          compName: null,
          compNameID: null,
          compType: null,
          compCity: "",
          compProvince: "",
        },
      },
      isLoading: false,
      showDownloadModal:false,
      showDownloadModalCSV:false,
      // end of filter
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1,
      },
      log_as: "",
      listData: [],
    };
  },
  methods: {
    getData() {
      this.isLoading = true
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
      API.get(`early-warning?type=hourly&page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
        .then(({data}) => {
          if (data.data == null) {
              this.listData = []
            } else {
              this.listData = data.data
              this.pagination.total = data.meta.pagination.total_items
            }

            this.isLoading = false
       
        })
        .catch((err) => {
          Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
          this.isLoading = false
        })
    },
    getCompanyType() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comp-types', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyType = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getCompanyName() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comps', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyName = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getProvince() {
    API.get(`provinces`)
      .then(({ data: content }) => {
        this.provideSelect.provinsi = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getMyCompliencePoint() {
       let headers = {
         Authorization: `Bearer ${localStorage.getItem('token')}`
       }

       API.get(`stations?comp_id=${this.userInput.companyName}` , {headers})
       .then(({data:content}) => {
        this.userInput.titikPenaatan = ""
         this.listDataCompliencePoints = content.data
       })
       .catch((err) => {
         console.log(err);
       })
    },
    getCityDepentOnProvince(data) {
      this.form.umum.compCity = []
      this.provideSelect.kabupatenKota = [];
      API.get(`kabkots?prov_id=${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBaseOnStatus() {
    this.isLoading = true

    let tmpDate = this.userInput.tanggal.split("to")

    let startDate = tmpDate[0]
    let endDate = tmpDate[1]


    if (endDate == undefined) {
      endDate = startDate
    } else if (endDate) {
      endDate.trim()
      startDate.trim()
    }

    const queryParams = this.queryParams();


    let headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`
    }

  
   

     const params = Object.fromEntries(
        Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
      );


    API.get(`early-warning` , { params, headers})
      .then(({data}) => {
        if (data.data == null) {
            this.listData = []
            this.isLoading = false
          } else {
            this.listData = data.data
            this.pagination.total = data.meta.pagination.total_items
            this.isLoading = false
          }
      })
      .catch((err) => {
        this.isLoading = false
        console.log(err);
      })
    },
    queryParams(){
      let tmpDate = this.userInput.tanggal.split(" to ");

      let startDate = tmpDate[0];
      let endDate = tmpDate[1];

      if (endDate == undefined) {
        endDate = startDate;
      } else if (endDate) {
        endDate.trim();
        startDate.trim();
      }

      const queryParams = {
        type: "hourly",
        // page: this.pagination.currentPage,
        // page_size: this.pagination.perPage,
        status: this.filterStatusPendaftaran,
        province_id: this.form.umum.compProvince,
        start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        kabkot_id: this.form.umum.compCity,
        comp_id: this.userInput.companyName,
        comp_type_id: this.userInput.companyType,
        station_id: this.userInput.titikPenaatan,
      };
      return queryParams
    },
    getDataDownload() {
      this.isLoading = true

      let tmpDate = this.userInput.tanggal.split("to")

      let startDate = tmpDate[0]
      let endDate = tmpDate[1]


      if (endDate == undefined) {
        endDate = startDate
      } else if (endDate) {
        endDate.trim()
        startDate.trim()
      }


      let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      const queryParams = {
        type:"hourly",
        is_export: true,
        status: this.filterStatusPendaftaran,
        province_id: this.form.umum.compProvince,
        start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        kabkot_id: this.form.umum.compCity,
        comp_id: this.userInput.companyName,
        comp_type_id: this.userInput.companyType,
        station_id: this.userInput.titikPenaatan,
      };

      const params = Object.fromEntries(
          Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
        );


        API.get(`early-warning` , { params, headers})
          .then(({data}) => {
            if (data.data == null) {
                this.listDataDownload = []
                this.isLoading = false
              } else {
                this.listDataDownload = data.data
                this.isLoading = false
              }
          })
          .catch((err) => {
            this.isLoading = false
            console.log(err);
          })
    },
    getCompliencePoint() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get(`stations` , {headers})
      .then(({data:content}) => {
        this.listDataCompliencePoints = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    downloadPDFNow() {
      if (!this.userInput.titikPenaatan) {
       return Swal.fire('Anda harus memilih titik penaatan','pilih titik penaatan yang ingin di unduh','error') 
      }

      this.getDataDownload()
      this.showDownloadModal = true      
    },
    downloadExcelNow() {
      if (!this.userInput.titikPenaatan) {
       return Swal.fire('Anda harus memilih titik penaatan','pilih titik penaatan yang ingin di unduh','error') 
      }

      this.getDataDownload()
      this.showDownloadModalCSV = true
    },
    async print() {
      try {
        // Dapatkan data dari objek
        const data = this.listDataDownload;

        // Buat dokumen jsPDF
        const doc = new jsPDF();

        // Tambahkan header
        const header = [
          "No",
          "Nama Industri",
          "Waktu",
          "Keterangan",
        ];

        let headerPemenuhan = [
                "No",
                "Pemantauan terus menerus dalam jaringan",
                "Jumlah Data yang Memenuhi Baku Mutu Air Limbah",
                "Presentase Pemenuhan Baku Mutu Air Limbah"
        ];
        let compName = ""
        let compAddress = ""
        let compLongitude = ""
        let compLatitude = ""
        let compTlp = ""
        let userEmail = ""
        let compType = ""
        let compSumberLimbah = ""
        let compTeknologi = ""
        let compNoIzin = ""
        let compInstansi = ""
        let compTglIzin = ""

        
        // Tambahkan baris data
        const rows = data.items.map((item, index) =>
          [
          index + 1,
          item.company_name,
          this.formatDate(item.timestamp),
          item.message,
        ]);

        // data.company.map((item,index)=>{
        //   compName = item.company_name
        //   compAddress = item.company_address
        //   compLongitude = item.bujur
        //   compLatitude = item.lintang
        //   compTlp = item.company_phone
        //   userEmail = item.company_email
        //   compType = item.company_type_name
        //   compSumberLimbah = item.sumber_air_limbah
        //   compTeknologi = item.jenis_teknologi_pengolahan_air_limbah
        //   compNoIzin = item.air_limbah_number
        //   compInstansi = item.pejabat_penerbit_izin
        //   compTglIzin = item.tanggal_penerbitan_izin
        // })

          compName = data.company.name
          compAddress = data.company.address
          compLongitude = "None"
          compLatitude = "None"
          compTlp = data.company.phone
          userEmail = data.company.email
          compType = data.company.company_type
          compSumberLimbah = data.sparing.sumber_air_limbah
          compTeknologi = data.sparing.jenis_teknologi_pengolahan_air_limbah
          compNoIzin = data.sparing_permit.air_limbah_number
          compInstansi = data.sparing_permit.pejabat_penerbit_izin
          compTglIzin = data.sparing_permit.tanggal_penerbitan_izin



        let bodyProfile = [
                ["NAMA INDUSTRI", compName],
                ["ALAMAT", compAddress],
                ["LATITUDE", compLongitude],
                ["LONGITUDE", compLatitude],
                ["NOMOR TELEPON", compTlp],
                ["EMAIL", userEmail],
                ["JENIS USAHA", compType],
                ["SUMBER LIMBAH", compSumberLimbah],
                ["TEKNOLOGI PENGOLAHAN LIMBAH", compTeknologi],
                ["NO. IZIN", compNoIzin],
                ["INSTANSI PENERBIT", compInstansi],
                ["TANGGAL TERBIT IZIN", compTglIzin]
        ];

        
        const logo = new Image();
        logo.src = '/img/brand/img-logo-color.png';

        // Tambahkan informasi profil di atas judul
        doc.addImage(logo, 'JPEG', 10, 10, 20, 20);
        doc.text("Kementerian Lingkungan Hidup", 35, 20, { lineHeight: 15 }); // Sesuaikan nilai lineHeight sesuai kebutuhan

        // Tambahkan judul
        doc.setFontSize(11);
        doc.text("Early Warning - perjam", 130, 20);
        doc.setTextColor(90, 90, 90)
        doc.setFont("bold");
        doc.text(`Data ini dibuat pada ${moment().format('DD-MM-YYYY HH:mm')}`, 130, 26);
        doc.setFont("normal");

      
        doc.autoTable({
          theme: "plain",
          body: bodyProfile,
          startY: 45, // Mulai di bawah judul profil
          columnStyles: {
            0: {
              fontStyle: "bold",
              halign: "left",
              cellWidth: 70,
              fillColor: [246, 249, 252],
            },
            1: { cellWidth: 100 },
          },
          styles: {
            cellPadding: 2,
            textColor: [41, 102, 75],
            // font: "times",
            lineWidth: 0.1,
            lineColor: [240, 240, 240],
            halign: "left",
          },
        });

        // Tambahkan tabel data di bawah informasi profil
        doc.autoTable({
          head: [header],
          theme: "striped",
          body: rows,
          headStyles: {
            fillColor: [41, 102, 75],
          },
          styles: {
            lineWidth: 0.2,
            halign: "center",
          },
        });

        doc.addPage();

          doc.autoTable({
                  head: [headerPemenuhan],
                  theme: "striped",
                  body: [['1','pH',3,100],['2','COD',3,100],['3','TSS',3,90],['4','NH3N',0,'Tidak Diwajibkan'],['5','Debit',3,100]],
                  headStyles: {
                    fillColor: [41, 102, 75]
                  },
                  styles: {
                    lineWidth: 0.2,
                    halign: "center"
                  },
          });

        // Simpan dokumen PDF
        doc.save("exportData.pdf");

        Swal.fire("Data di Unduh", "", "success");
        this.showDownloadModal = false;
      } catch (error) {
        console.error("Error:", error);
        // Tambahkan penanganan kesalahan jika diperlukan
      }
    },
    ExportExcel(type, fn, dl) {
        var elt = this.$refs.exportable_table;
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        Swal.fire('Data di Unduh','','success')
        this.showDownloadModalCSV = false
        return dl ?
            XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
            XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
    },
    reportTableState() {
        return []
    },
    // end new
    formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm');
      return humanDate;
    },
    changeSizePage() {
      this.getData();
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.getData();
    }
  },
};
</script>
